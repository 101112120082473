<template>
  <v-layout column>
    <v-flex xs12>
      <v-card flat>
        <v-card-title>
          <h3>Hebesatzkonflikte ({{ companyName }})</h3>
          <v-spacer />
          <company-selector />
        </v-card-title>
        <v-data-table
          :loading="loading"
          :pagination.sync="pagination"
          :total-items="serverData.total"
          :rows-per-page-items="[serverData.rowsPerPage]"
          disable-initial-sort
          v-bind="{ headers, items }"
        >
          <tr
            slot="items"
            slot-scope="props"
            @click="
              $router.push({
                name: 'MasterDataView',
                params: { id: props.item.id },
              })
            "
          >
            <td>{{ props.item.ags }}</td>
            <td>
              {{ props.item.municipality }}
            </td>

            <td>{{ props.item.uniformMunicipalRateSince }}</td>
            <td>
              <v-chip
                v-for="rate in prepareRate(
                  props.item.municipalRate,
                  props.item.uniformMunicipalRateSince
                )"
                :key="rate.startYear"
                >{{ rate.startYear
                }}<template v-if="rate.endYear !== rate.startYear">
                  - {{ rate.endYear }}</template
                >: <b class="ml-1">{{ rate.value }}</b>
              </v-chip>
            </td>
          </tr>
          <template slot="pageText" slot-scope="props">
            Einträge {{ props.pageStart }} bis {{ props.pageStop }} von
            insgesamt
            {{ props.itemsLength }}
          </template>
        </v-data-table>
      </v-card></v-flex
    ></v-layout
  >
</template>

<script>
import companiesApi from '@/api/companiesApi'
import { mapGetters, mapState } from 'vuex'
import CompanySelector from '@/components/CompanySelector'

export default {
  name: 'Conflicts',
  components: { CompanySelector },
  data: () => ({
    loading: true,
    serverData: {
      total: 0,
      rowsPerPage: 15,
    },
    items: [],
    pagination: {
      page: 1,
    },
    headers: [
      { text: 'AGS', value: 'ags', sortable: false },
      { text: 'Gemeindename', value: 'municipality', sortable: false },
      {
        text: 'Einh. Hebesatz seit',
        value: 'uniformMunicipalRateSince',
        sortable: false,
      },
      { text: 'Hebesätze', value: 'municipalRate', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(['currentCompany']),
    ...mapState(['currentCompanyId']),
    companyName() {
      return this.currentCompany?.name || ''
    },
  },
  methods: {
    prepareRate(array, rate) {
      const filtered = array.filter(
        ({ year, value }) => Number(year) >= Number(rate) && value !== null
      )
      let res = [
        {
          startYear: filtered[0].year,
          endYear: filtered[0].year,
          value: filtered[0].value,
        },
      ]
      filtered.forEach(({ year, value }) => {
        const lastRes = res[res.length - 1]
        if (lastRes.value === value) lastRes.endYear = year
        else res.push({ startYear: year, endYear: year, value })
      })
      return res
    },
    async getData(page) {
      try {
        this.loading = true
        this.serverData = await companiesApi.conflicting(
          this.currentCompanyId,
          page
        )
        this.items = this.serverData.data
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    this.getData()
  },
  watch: {
    currentCompanyId() {
      this.getData()
    },
    pagination({ page }) {
      this.getData(page)
    },
  },
}
</script>

<style scoped></style>
